import React from "react";
import { Box, Nav, Anchor, Text, Heading, Menu } from "grommet";

export default function Agade() {
  return (
    <>
      <Heading level={2}>Agade Mailing List</Heading>
      <Text>
        The Agade list forwards news and information of interest to scholars in
        Biblical studies, Near Eastern studies, Late Antiquity, Judaica,
        Classics, archaeology, and even broad intellectual history. It is a
        valuable source of announcements on recent books, forthcoming lectures,
        and appeals for contributions at professional meetings.
      </Text>
      <Text>
        --- To subscribe please send a blank email to <b>listserv@unc.edu</b>,
        and write (as subject and in first line): subscribe agade
      </Text>
      <Anchor href="https://agade.hittites.org" target="_blank">
        <Heading level={4}>Agade Archive</Heading>
      </Anchor>
    </>
  );
}
