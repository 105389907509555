import { Page, PageContent, Grommet, Main } from "grommet";
import { hpe } from "grommet-theme-hpe";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Agade from "./components/Agade";
import Hpm from "./components/Hpm";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <Grommet theme={hpe}>
      <Main>
        <Page>
          <BrowserRouter>
            <NavBar />
            <PageContent>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Home />
                      <Agade />
                    </>
                  }
                />
                <Route path="/agade" element={<Agade />} />
                <Route path="/hpm" element={<Hpm />} />
              </Routes>
            </PageContent>
          </BrowserRouter>
        </Page>
      </Main>
    </Grommet>
  );
}

export default App;
