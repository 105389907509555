import React from "react";
import { Box, Nav, Anchor, Text, Heading, Menu, Image } from "grommet";

export default function Hpm() {
  return (
    <>
      <Anchor
        href="https://www.hethport.uni-wuerzburg.de/HPM/index.php"
        target="_blank"
      >
        <Image
          width="60%"
          src="https://www.hethport.uni-wuerzburg.de/HPMctrl/logos/schrift-logo-weiss6symb720trapa.png"
        />
      </Anchor>
      <Heading level={2}>CTH</Heading>
      <Text>What is the CTH number of the Apologie Ḫattušilis III?</Text>
      <Heading level={2}>Konkordanz</Heading>
      <Text>Find Inventory Number Bo 86/299</Text>
      <Text>Publication: KUB 36.98</Text>
      <Heading level={2}>Textcorpora</Heading>
      <Text>
        Find the translation of Gebet Arnuwandas I. und Ašmunikkals an die
        Sonnengöttin von Arinna
      </Text>
      <Heading level={2}>HRF: Festival Texts</Heading>
    </>
  );
}
