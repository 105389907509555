import React from "react";
import { Box, Nav, Anchor, Text, Heading, Menu } from "grommet";
import { ResponsiveContext } from "grommet";

export default function Home() {
  // also add cuneiform.hittites.org to the list of links
  return (
    <>
      <Heading level={1}>Digital Hittitology: Sources and Tutorials</Heading>
      <Heading level={2}>Sources</Heading>
      <Box direction="column" gap="small">
        <Anchor href="/hpm" target="_blank">
          <Heading level={4}>HPM</Heading>
        </Anchor>
        <Anchor href="http://www.hittitemonuments.com/" target="_blank">
          <Heading level={4}>Hittite Monuments</Heading>
        </Anchor>
        <Anchor href="https://laman.hittites.org" target="_blank">
          <Heading level={4}>Laman: Hittite Name Finder</Heading>
        </Anchor>
        <Anchor href="https://cuneiform.hittites.org" target="_blank">
          <Heading level={4}>E.DUB.BA: School of Hittite Cuneiform</Heading>
        </Anchor>
      </Box>
    </>
  );
}
